(function ($) {
    'use strict';

    /* Language */
    var lang = $('body').data('lang');

    var STMNT = {
        /* Initiate all functions, that need to be loaded on the start */
        init: function (debug = false) {
            this.debug = debug;
            this.start();
            this.accessibility();
            this.swipe_menu();
            this.link_icon();
            this.slick_slider_testimonials();
            $(window).on('resize', STMNT.slick_slider_testimonials());
            this.tab_swipe();
            this.sidebar_mobile_close_click();
            this.sidebar_keyboard_click();
            this.scroll_sidebar();
            this.reload_formular();
            this.mail_changes();
            this.check_invalid_form();
            this.check_sidebar_length();
            this.extend_sidebar_tap();
            this.iban_syntax();
            this.iframe_size();
            console.log(
                '%cLoaded STMNT Theme successfully',
                'background-color: #323A45; color: lightgreen; font-family: "Arial"; font-size: 14px;'
            );
        },

        /* Start execution function */
        start: function () {
            if (this.debug) {
                console.log('Execute: Start');
            }
        },

        /* Some extra stuff for accessibility */
        accessibility: function () {
            $('.elementor-field-label').each(async (index, field) => {
                if ($(field).parent().hasClass('elementor-mark-required')) {
                    $(field).addClass('custom-mark');
                    $(field).append('<span>*</span>');
                }
            });
        },

        /* Add icon to link to show if external or not */
        link_icon: function () {
            let asset_path = $('.stmnt--main-wrapper').attr('data-assets-path');
            $('.stmnt--content-area')
                .find('a')
                .each(async (index, link) => {
                    let external = false;
                    let download = false;
                    if ($(link).attr('target') == '_blank') {
                        external = true;
                    }
                    if (
                        $(link).attr('href').endsWith('.pdf') ||
                        $(link).attr('href').endsWith('.xlsx') ||
                        $(link).attr('href').endsWith('.xls') ||
                        $(link).attr('href').endsWith('.jpg') ||
                        $(link).attr('href').endsWith('.jpeg') ||
                        $(link).attr('href').endsWith('.png') ||
                        $(link).attr('href').endsWith('.doc') ||
                        $(link).attr('href').endsWith('.zip') ||
                        $(link).attr('href').endsWith('.tar.gz') ||
                        $(link).attr('href').endsWith('.rar') ||
                        $(link).attr('href').endsWith('.docx')
                    ) {
                        download = true;
                    }
                    if (external && !download) {
                        $(link).append(
                            '<img loading="lazy" alt="External Link Icon" class="link-explain-image" src="' +
                                asset_path +
                                '/src/icons/bootstrap-link.svg" title="Externer Link" aria-label="Externer Link" tabindex="0" role="button">'
                        );
                    }
                    if ((external && download) || (download && !external)) {
                        $(link).append(
                            '<img loading="lazy" alt="Download Link Icon" class="link-explain-image" src="' +
                                asset_path +
                                '/src/icons/bootstrap-download.svg" title="Download Link" aria-label="Download Link" tabindex="0" role="button">'
                        );
                    }
                });
        },

        /* Function for initiate mobile swipe menu, for android phone devices, implement custom scroll */
        swipe_menu: function () {
            /* Get the current device */
            var userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
            let the_device = 'unknown';

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                the_device = 'Windows Phone';
            } else if (/android/i.test(userAgent)) {
                the_device = 'Android';
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                // iOS detection from: http://stackoverflow.com/a/9039885/177710
                the_device = 'iOS';
            }

            /* Some variables */
            let window_width = $(window).width();
            let sidebar = $('#js--swipe-sidebar');
            let toggle_menu = $('.js--toggle-menu');
            let space_distance = 0.5;
            let start_space = 0.95;

            if (window_width <= 575) {
                start_space = 0.94;
            }
            if (window_width <= 420) {
                start_space = 0.92;
            }

            /* Open mobile swipe menu, if click on "menu" button */
            $('.js--toggle-sidebar').on('click', function () {
                if (sidebar.hasClass('open')) {
                    sidebar.scrollTop = 0;
                    sidebar.attr('style', '');
                    sidebar.removeClass('open');
                } else {
                    sidebar.scrollTop = 0;
                    sidebar.attr('style', '');
                    sidebar.addClass('open');
                }
                toggle_menu.toggleClass('open');
            });

            /* Add correct class to sidebar in mobile view */
            if (window_width <= 767) {
                sidebar.addClass('mobile');
            } else {
                sidebar.removeClass('mobile');
            }

            /* Only do swipes in mobile view */
            if (sidebar.hasClass('mobile')) {
                $('#js--swipe-sidebar, #swipeextend').swipe({
                    allowPageScroll: 'vertical',

                    swipeStatus: function (event, phase, direction, distance) {
                        /* Implement custom touch scroll with touchSwipe on mobile menu */
                        if (window_width <= 767) {
                            let scroll_smoothness = 24;
                            if (direction == 'up') {
                                if (event.cancelable) {
                                    event.preventDefault();
                                }
                                let current_scroll_position =
                                    $(sidebar).scrollTop();
                                let scroll_distance =
                                    distance / scroll_smoothness;

                                $(sidebar).scrollTop(
                                    current_scroll_position + scroll_distance
                                );
                            }

                            if (direction == 'down') {
                                if (event.cancelable) {
                                    event.preventDefault();
                                }
                                let current_scroll_position =
                                    $(sidebar).scrollTop();
                                let scroll_distance =
                                    distance / scroll_smoothness;

                                $(sidebar).scrollTop(
                                    current_scroll_position - scroll_distance
                                );
                            }
                        }

                        let space = window_width * space_distance;
                        let space_convert = window_width * (1 - space_distance);

                        /* Only do transtition if moving or ending, not in start */
                        if (distance > 0 && phase != 'start') {
                            sidebar.css('transition', 'unset');
                        }

                        /* Start moving */
                        if (phase == 'move') {
                            /* Moves to the left */
                            if (direction == 'left') {
                                let start_value;
                                if (!sidebar.hasClass('open')) {
                                    start_value = window_width * start_space;
                                } else {
                                    start_value = 0;
                                }
                                let new_value = (start_value + distance) * -1;
                                if (new_value < -(window_width * start_space)) {
                                    new_value = -(window_width * start_space);
                                }
                                sidebar.css(
                                    'transform',
                                    'translateX(' + new_value + 'px)'
                                );
                            }
                            /* Moves to the right */
                            if (direction == 'right') {
                                let start_value;
                                if (!sidebar.hasClass('open')) {
                                    start_value =
                                        start_space * window_width * -1;
                                } else {
                                    start_value = 0;
                                }
                                let new_value = start_value + distance;
                                if (new_value > 0) {
                                    new_value = 0;
                                }
                                sidebar.css(
                                    'transform',
                                    'translateX(' + new_value + 'px)'
                                );
                            }
                        }

                        /* What happens on move right */
                        if (direction == 'right') {
                            /* If it hite the corner */
                            if (distance > window_width - space) {
                                /* If it stops */
                                if (phase == 'cancel' || phase == 'end') {
                                    sidebar.addClass('open');
                                    toggle_menu.addClass('open');
                                    sidebar.css(
                                        'transition',
                                        'all .3s ease-in-out'
                                    );
                                    sidebar.css('transform', 'translateX(0px)');
                                }
                            } else {
                                if (phase == 'cancel' || phase == 'end') {
                                    sidebar.removeClass('open');
                                    toggle_menu.removeClass('open');
                                    sidebar.css(
                                        'transition',
                                        'all .3s ease-in-out'
                                    );
                                    sidebar.css(
                                        'transform',
                                        'translateX(-' +
                                            window_width * start_space +
                                            'px)'
                                    );
                                }
                            }
                        }

                        /* What happens on move left */
                        if (direction == 'left') {
                            /* If it hite the corner */
                            if (distance > window_width - space_convert) {
                                /* If it stops */
                                if (phase == 'cancel' || phase == 'end') {
                                    sidebar.removeClass('open');
                                    toggle_menu.removeClass('open');
                                    sidebar.css(
                                        'transition',
                                        'all .3s ease-in-out'
                                    );
                                    sidebar.css(
                                        'transform',
                                        'translateX(-' +
                                            window_width * start_space +
                                            'px)'
                                    );
                                }
                            } else {
                                if (phase == 'cancel' || phase == 'end') {
                                    sidebar.addClass('open');
                                    toggle_menu.addClass('open');
                                    sidebar.css(
                                        'transition',
                                        'all .3s ease-in-out'
                                    );
                                    sidebar.css('transform', 'translateX(0px)');
                                }
                            }
                        }
                    },
                });
            }
        },

        /* Function for the testimonials as carousel with slick slide */
        slick_slider_testimonials: function () {
            $('.js--slick-slider')
                .not('.slick-initialized')
                .slick({
                    dots: false,
                    infinite: true,
                    speed: 500,
                    fade: false,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    autoplay: true,
                    autoplaySpeed: 15000,
                    swipe: false,
                    regionLabel: 'Testimonial Krebsregister',
                    nextArrow:
                        '<button role="button" tabindex="0" onclick="swipe_testimonial_slider__next()" class="next-slick slick-next slick-arrow" aria-label="Next" type="button" style=""><div class="inner"></div>Next</button>',
                    prevArrow:
                        '<button role="button" tabindex="0" onclick="swipe_testimonial_slider__prev()" class="prev-slick slick-prev slick-arrow" aria-label="Previous" type="button" style=""><div class="inner"></div>Previous</button>',
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                swipe: true,
                            },
                        },
                    ],
                });

            $('.js--slick-slider').find('img').attr('role', 'button');
            $('.js--slick-slider').find('.slick-list').attr('tabindex', '0');
            $('.js--slick-slider').find('.slick-list').attr('role', 'button');
            $('.js--slick-slider').find('.next-slick').attr('tabindex', '0');
            $('.js--slick-slider').find('.prev-slick').attr('tabindex', '0');

            $('.js--slick-slider')
                .find('.slick-slide')
                .each(async (index, slide) => {
                    $(slide).attr('aria-hidden', 'false');
                });
        },

        /* Function for tab swipe effects with arrow under the tabs */
        tab_swipe: function () {
            let tabs = $('.nav-tabs .nav-item');
            let length = tabs.width();
            let border = $('.tab-border');
            let value = 0;

            tabs.each((index, element) => {
                if ($(element).find('.nav-link').hasClass('active')) {
                    value = index * length;
                    $(border).css('transform', 'translateX(' + value + 'px)');
                }
                $(element).on('click', function () {
                    value = index * length;
                    $(border).css('transform', 'translateX(' + value + 'px)');
                });
            });
        },

        /* On click link in sidebar, the sidebar will be automatically closed in mobile view */
        sidebar_mobile_close_click: function () {
            $('.stmnt--sidebar a').on('click', function (event) {
                $('.stmnt--sidebar').removeClass('open');
            });
        },

        /* Make sidebar openable with keyboard */
        sidebar_keyboard_click: function () {
            $('#js--toggle-sidebar').keypress(function (event) {
                if (event.keyCode === 13) {
                    $('#js--toggle-sidebar').trigger('click');
                }
            });

            $('.js--slick-slider').keypress(function (event) {
                if (event.keyCode === 13) {
                    $(this).find('.slick-next').trigger('click');
                }
            });
        },

        /* Keep "menu"-button on top, if sidebar is getting scrolled */
        scroll_sidebar: function () {
            $('#js--swipe-sidebar').on('scroll', function () {
                $('#js--toggle-sidebar')
                    .parent()
                    .css('transform', 'translateY(' + this.scrollTop + 'px)');
            });
        },

        /* Function for: Reloading the formulur included by iFrames */
        reload_formular: function () {
            $('.js--reload-formular-button').on('click', function () {
                let data = $(this).attr('data-content');
                let iframe = $('iframe.' + data);
                let source = $(iframe).attr('src');
                $(iframe).attr('src', source);
            });
        },

        /* Function for: Searches for a links, beginning with "mailto", add a character to break the a content on small views (mobile) */
        mail_changes: function () {
            /* For mails */
            let mails = $('a[href^="mailto"]');
            mails.each((index, element) => {
                let get_content = $(element).text();
                let mail_array = get_content.split('');
                let at_position = get_content.indexOf('@');
                mail_array.splice(at_position, 0, '&shy;');
                let new_href = mail_array.join('');
                $(element).html(new_href);
            });

            /* For website links */
            let links = $('a[href^="http://"], a[href^="https://"]');
            links.each((index, element) => {
                let link_text = $(element).text();
                if (
                    link_text.includes('http://') ||
                    link_text.includes('https://') ||
                    link_text.includes('www.')
                ) {
                    let link_array = link_text.split('');
                    link_array.splice(link_text.length / 2, 0, '&shy;');
                    let new_href = link_array.join('');
                    $(element).html(new_href);
                }
            });
        },

        /**
         * Check the iFrame size and fit to content (only works if iFrame is included from same url)
         * I takes the attribute "data-height" from the iframe, which is set from the database-script after a timeout of 1000 ms (loading stuff from database),
         * and sets this height as new height. Min-height is always 950px.
         * Sets and removes loading animations with dots
         */
        iframe_size: function () {
            $('#icd9iframe').on('load', function () {
                if (
                    $(this.contentWindow.document.body).find('iframe').length <=
                    0
                ) {
                    let loading_container = $('<div></div>');
                    loading_container.addClass('loading-dots');

                    let loading_stuff =
                        '<div class="stage"><div class="dot-flashing"></div></div>';
                    loading_container.append(loading_stuff);
                    $('.tab-pane').append(loading_container);
                }

                /* For ICD9 */
                setTimeout(function () {
                    let iframe = document.getElementById('icd9iframe');
                    let new_height =
                        iframe.contentWindow.document.body.getAttribute(
                            'data-height'
                        );
                    if (parseInt(new_height) < 1150) {
                        new_height = 1150;
                    }
                    $(iframe).height(new_height);
                    $('.loading-dots').remove();
                }, 1100);
            });

            $('#icd10iframe').on('load', function () {
                if (
                    $(this.contentWindow.document.body).find('iframe').length <=
                    0
                ) {
                    let loading_container = $('<div></div>');
                    loading_container.addClass('loading-dots');

                    let loading_stuff =
                        '<div class="stage"><div class="dot-flashing"></div></div>';
                    loading_container.append(loading_stuff);
                    $('.tab-pane').append(loading_container);
                }

                /* For ICD10 */
                setTimeout(function () {
                    let iframe = document.getElementById('icd10iframe');
                    let new_height =
                        iframe.contentWindow.document.body.getAttribute(
                            'data-height'
                        );
                    if (parseInt(new_height) < 1150) {
                        new_height = 1150;
                    }
                    $(iframe).height(new_height);
                    $('.loading-dots').remove();
                }, 1105);
            });
        },

        /**
         * Functions for security for mails: Crypt and uncrypt mailto links, to protect those from spam attacks
         * !!!! CURRENTLY NOT USED, CODE IS ONLY HERE FOR POSSIBLE FUTURE USES !!!!
         * Example:
         * <a href="javascript:link_to_uncrypt_mailto('nbjmup;');"></a>
         */
        uncrypt_mailto: function (s) {
            var n = 0;
            var r = '';
            for (var i = 0; i < s.length; i++) {
                n = s.charCodeAt(i);
                if (n >= 8364) {
                    n = 128;
                }
                r += String.fromCharCode(n - 1);
            }
            return r;
        },

        /* Get the uncrypted mail back */
        link_to_uncrypt_mailto: function (s) {
            location.href = uncrypt_mailto(s);
        },

        /* Check on submit if the formular form has invalid values, then give red border to it, remove after new input */
        check_invalid_form: function () {
            $('form')
                .find('input:invalid, textarea:invalid, input[type="number"]')
                .each((index, element) => {
                    $(element).on('input', function () {
                        $(element).css('border', '1.5px solid #6c80a3');
                    });
                });

            /* Hide but display checkbox for custom checkbox style */
            $(
                'form .elementor-field-type-acceptance, form .elementor-field-type-checkbox'
            )
                .find('input[type="checkbox"][required="required"]')
                .each((index, element) => {
                    $(element).css('display', 'flex');
                    $(element).css('height', '1px');
                    $(element).css('position', 'absolute');
                    $(element).css('margin-top', '13px');
                    $(element).css('opacity', '0');
                });

            $(
                'form .elementor-field-type-acceptance, form .elementor-field-type-checkbox'
            )
                .find('input[type="checkbox"][required="required"]')
                .each((index, element) => {
                    $(element).on('input', function () {
                        let checked = $(element).is(':checked');
                        if (checked === true) {
                            $(element).siblings().removeClass('notchecked');
                        }
                    });
                });

            $('.js--check-form').on('click', function () {
                $('form')
                    .find('input:invalid, textarea:invalid')
                    .each((index, element) => {
                        $(element).css('border', '1.5px solid red');
                    });
                $(
                    'form .elementor-field-type-acceptance, form .elementor-field-type-checkbox'
                )
                    .find('input[type="checkbox"][required="required"]')
                    .each((index, element) => {
                        let checked = $(element).is(':checked');
                        if (checked === false) {
                            $(element).siblings().addClass('notchecked');
                        } else {
                            $(element).siblings().removeClass('notchecked');
                        }
                    });
            });
        },

        /**
         * Function, that checks, if the sidebar menu content is longer then the window height.
         * If true, makes it scrollable
         * Only on windows > 767px
         */
        check_sidebar_length: function () {
            let around_height = $('.js--menu-around').outerHeight();
            $(window).on('resize load', function () {
                let window_height = $(window).height();
                let window_width = $(window).width();
                let offset_top = 170;
                let space = 0;

                if (window_width > 767) {
                    let overall_height = offset_top + around_height;

                    if (overall_height - space > window_height) {
                        $('#js--swipe-sidebar').addClass('scrollable');
                        $('#js--swipe-sidebar').css(
                            'height',
                            window_height - offset_top + 'px'
                        );
                    } else {
                        $('#js--swipe-sidebar').removeClass('scrollable');
                        $('#js--swipe-sidebar').css('height', 'unset');
                    }
                } else {
                    $('#js--swipe-sidebar').css('height', '100%');
                }
            });
        },

        /* Parse the matrix from the css transform values, used for extended sidemenu tapbar, to keep it right next to the sidebar */
        parse_matrix: function (element) {
            const style = window.getComputedStyle(element);
            const matrix = style['transform'] || style.mozTransform;

            /* No transform property. Simply return 0 values */
            if (matrix === 'none') {
                return {
                    x: 0,
                    y: 0,
                    z: 0,
                };
            }

            /* Can either be 2d or 3d transform */
            const matrixType = matrix.includes('3d') ? '3d' : '2d';
            const matrixValues = matrix
                .match(/matrix.*\((.+)\)/)[1]
                .split(', ');

            /* 2d matrices have 6 values, last 2 values are X and Y, 2d matrices does not have Z value */
            if (matrixType === '2d') {
                return {
                    x: matrixValues[4],
                    y: matrixValues[5],
                    z: 0,
                };
            }

            /* 3d matrices have 16 values, the 13th, 14th, and 15th values are X, Y, and Z */
            if (matrixType === '3d') {
                return {
                    x: matrixValues[12],
                    y: matrixValues[13],
                    z: matrixValues[14],
                };
            }
        },

        /* The extended tapbar for the sidebar, keep it always right next to the sidebar with this function */
        extend_sidebar_tap: function () {
            let extend_bar = document.getElementById('swipeextend');
            let sidebar = document.getElementById('js--swipe-sidebar');

            let matrix = this.parse_matrix(sidebar);
            let transform = $(sidebar).outerWidth() - matrix['x'] * -1;
            $(extend_bar).css('transform', 'translateX(' + transform + 'px)');

            $(document).on('load resize', function () {
                let matrix = this.parse_matrix(sidebar);
                let transform = $(sidebar).outerWidth() - matrix['x'] * -1;
                $(extend_bar).css(
                    'transform',
                    'translateX(' + transform + 'px)'
                );
            });
        },

        /* Function for checking the syntax of the iban, and gives the value a structure (DOES NOT CHECK IF IBAN SYNTAX IS CORRECT)
         * Global IBAN variable from iban.js in vendors
         */
        iban_syntax: function () {
            $('input#form-field-iban').on('input', function (event) {
                event.preventDefault();

                $(this).attr('maxlength', 27);

                let input = $(this);
                let value = input.val();

                if (!IBAN.isValid(value) && value.length > 0) {
                    input.css('border', '1.5px solid red');
                } else {
                    input.css('border', '1.5px solid #6c80a3');
                }

                if (value.replace(/\s/g, '').length <= 22) {
                    $(this).val(IBAN.printFormat(value, ' '));
                } else {
                    $(this).val(IBAN.printFormat(value, ' ').slice(0, -1));
                }
            });
        },
    };

    /* Initiate all the functions */
    STMNT.init();
})(jQuery);
